import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import { auth } from './firebaseConfig';
import Navbar from "./components/Navbar";
import Home from "./pages/Home";
import About from "./pages/About";
import Projects from "./pages/Projects";
import Dashboard from "./pages/Dashboard";
import Profile from "./pages/Profile";
import Login from "./pages/Login";
import Leaderboard from "./pages/Leaderboard";
import SignUp from "./pages/SignUp";
import Success from "./pages/Success";
import Cancel from "./pages/Cancel";
import AdminDashboard from "./pages/AdminDashboard";
import LogoutButton from "./components/LogoutButton";
import FeedbackForm from "./pages/FeedbackForm";
import LoginButton from "./components/LoginButton";
import ForgotPassword from "./pages/ForgotPassword";
import SubscribeButton from "./components/SubscribeButton";
import "./styles/App.css";
import { AuthProvider, useAuth } from "./auth/authProvider";
import { GameProvider, useGame } from "./auth/gameProvider";
import RequireAdmin from "./auth/requireAdmin";
import RequireAuth from "./auth/requireAuth";
import RequirePlayer from "./auth/requirePlayer";
import Error404 from "./pages/error404";
import ModalComponent from "./components/DonateModalComponent";
import Memberships from "./pages/Memberships"; // Import the Memberships component
import { UIProvider } from "./utils/UIContext";
import AlertBar from "./components/Alertbar";
import MangroveApp from "./MangroveApp";
import EventCalendar from "./components/EventCalendar";
import Lobby from "./pages/Lobby";
import UploadPhoto from "./pages/UploadPhoto";
import ReferralChallenge from "./pages/ReferralChallenge";

function App() {
  const auth = useAuth();
  const [isSubscribeModalOpen, setIsSubscribeModalOpen] = useState(false);
  const [showNewsletter, setShowNewsletter] = useState(true);
  const game = useGame();

  // useEffect(() => {
  //   const visited = localStorage.getItem('visited');
  //   if (!visited) {
  //     // If the user hasn't visited before, set showNewsletterModal to true
  //     setShowNewsletter(true);
  //     // Set visited in local storage to prevent showing the modal again
  //     localStorage.setItem('visited', 'true');
  //   }
  // }, [])

  const closeNewsletterModal = () => {
    setShowNewsletter(false);
  };

  return (
    <Router>
      <UIProvider>
        <AuthProvider>
          <GameProvider>
            <div className="App">
              <Navbar />
              <AlertBar />
              <Routes>
                {game && game.player ? (
                  <Route
                    path="/lobby"
                    element={
                      <RequirePlayer>
                        <Lobby />
                      </RequirePlayer>
                    }
                  />
                ) : (
                  <Route path="/" element={<Lobby />} />
                )}
                <Route path="/" element={<Lobby />} />
                <Route
                  path="/lobby"
                  element={
                    <RequirePlayer>
                      <Lobby />
                    </RequirePlayer>
                  }
                />
                <Route path="/home" element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route path="/projects" element={<Projects />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route
                  path="/profile"
                  element={
                    <RequireAuth>
                      <Profile />
                    </RequireAuth>
                  }
                />
                <Route path="/leaderboard" element={<Leaderboard />} />
                <Route path="/memberships" element={<Memberships />} />
                <Route path="/success" element={<Success />} />
                <Route path="/cancel" element={<Cancel />} />
                <Route path="/signup" element={<SignUp />} />
                <Route path="/login" element={<Login />} />
                <Route
                  path="/admin"
                  element={
                    <RequireAdmin>
                      <AdminDashboard />
                    </RequireAdmin>
                  }
                />
                <Route path="/calendar" element={<EventCalendar />} />
                <Route path="/feedback-form" element={<FeedbackForm />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/mangroveapp" element={<MangroveApp />} />
                <Route path="/lobby/upload-photo-challenge"
                  element={<RequirePlayer><UploadPhoto /> </RequirePlayer>}/>
                <Route path="/referral" element={<ReferralChallenge />} />
                <Route path="*" element={<Error404 />} />
              </Routes>
              <AuthButtons />
              <SubscribeButton onClick={() => setIsSubscribeModalOpen(true)} />
              {isSubscribeModalOpen && (
                <ModalComponent
                  onClose={() => setIsSubscribeModalOpen(false)}
                />
              )}
            </div>
          </GameProvider>
        </AuthProvider>
      </UIProvider>
    </Router>
  );
}

function AuthButtons() {
  const { user, setUser } = useAuth();

  if (user) {
    return <LogoutButton />;
  } else {
    return <LoginButton />;
  }
}

export default App;
