import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/Dashboard.css'; // Make sure this path is correct
import feedbackIcon from '../images/feedback.png'; // Make sure this path is correct
import LinkedinIcon from '../images/linkedin.png'; // Make sure this path is correct
import InstagramIcon from '../images/instagram.png'; // Make sure this path is correct
import XIcon from '../images/twitter.jpeg'; // Make sure this path is correct
import FBIcon from '../images/facebook.png'; // Make sure this path is correct
import ActionIcon from '../images/action.png'; // Make sure this path is correct
import EducationIcon from '../images/elearning.png'; // Make sure this path is correct
import FinancialsIcon from '../images/financials.png'; // Make sure this path is correct
import ProjectPropIcon from '../images/project.png';
import CalendarIcon from '../images/calendar.png'
import MangroveIcon from '../images/mangrove.png'

function Dashboard() {
  return (
    <div className="dashboard-container">
      <h1 className="dashboard-header">Dashboard</h1>
      <div className="dashboard-button-container">

        {/* First row of 4 buttons */}
        <Link to="/feedback-form" className="dashboard-button">
          <img src={feedbackIcon} alt="" className="dashboard-button-icon" />
          <div className="dashboard-button-text">Feedback</div>
        </Link>
        <Link to="/calendar" className="dashboard-button">
          <img src={CalendarIcon} alt="" className="dashboard-button-icon" />
          <div className="dashboard-button-text">Calendar</div>
        </Link>
        <a href='https://docs.google.com/forms/d/e/1FAIpQLSe-q-w8p9Dc_N05O4-N4OYPyNR869D60CVMVNh_TMKBIXV0hw/viewform' className="dashboard-button" target="_blank" rel="noopener noreferrer">
          <img src={ProjectPropIcon} alt="" className="dashboard-button-icon" />
          <div className="dashboard-button-text">Propose New Project</div>
        </a>
        <a href="https://drive.google.com/drive/folders/1w-VInZ9rLtcPXy7iMIbckulBqvntSy-J?usp=sharing" className="dashboard-button" target="_blank" rel="noopener noreferrer">
          <img src={FinancialsIcon} alt="" className="dashboard-button-icon" />
          <div className="dashboard-button-text">Financials</div>
        </a>
        <a href="https://drive.google.com/drive/folders/1xkJi01ENIOFdx-D9zgqXUG0BiOO1ev_w?usp=sharing" className="dashboard-button" target="_blank" rel="noopener noreferrer">
          <img src={ActionIcon} alt="" className="dashboard-button-icon" />
          <div className="dashboard-button-text">Proof of Action</div>
        </a>
        <a href="https://drive.google.com/drive/folders/1A869moNsvUub8IxTHcxLhgnGDb6mXNYW?usp=drive_link" className="dashboard-button" target="_blank" rel="noopener noreferrer">
          <img src={EducationIcon} alt="" className="dashboard-button-icon" />
          <div className="dashboard-button-text">Educational Resources</div>
        </a>

        {/* Second row of 4 buttons */}

        <a href="https://www.instagram.com/amaearthgroup/" className="dashboard-button" target="_blank" rel="noopener noreferrer">
          <img src={InstagramIcon} alt="" className="dashboard-button-icon" />
          <div className="dashboard-button-text">Instagram</div>
        </a>
        <a href="https://twitter.com/amaearthgroup" className="dashboard-button" target="_blank" rel="noopener noreferrer">
          <img src={XIcon} alt="" className="dashboard-button-icon" />
          <div className="dashboard-button-text">X/Twitter</div>
        </a>
        <a href="https://www.facebook.com/profile.php?id=61550248906282" className="dashboard-button" target="_blank" rel="noopener noreferrer">
          <img src={FBIcon} alt="" className="dashboard-button-icon" />
          <div className="dashboard-button-text">Facebook</div>
        </a>
        <a href="https://www.linkedin.com/company/amaearthgroup/" className="dashboard-button" target="_blank" rel="noopener noreferrer">
          <img src={LinkedinIcon} alt="" className="dashboard-button-icon" />
          <div className="dashboard-button-text">LinkedIn</div>
        </a>
        {/*
        <Link to="/mangroveapp" className="dashboard-button">
          <img src={MangroveIcon} alt="" class="dashboard-button-icon" />
            <div class="dashboard-button-text">MMAPP</div>
        </Link>
        */}
      </div>
    </div>
  );
}

export default Dashboard;
