import React, { useState, useEffect, useCallback } from 'react';
import { Typography } from "@mui/material";

const Countdown = React.memo(({ upcomingGiveaways, onGiveawayStateChange }) => {
    const [countdownTime, setCountdownTime] = useState(0);
    const [currentGiveawayIndex, setCurrentGiveawayIndex] = useState(0);
    
    const formatTime = (ms) => {
      const seconds = Math.floor((ms / 1000) % 60);
      const minutes = Math.floor((ms / (1000 * 60)) % 60);
      const hours = Math.floor((ms / (1000 * 60 * 60)) % 24);
      const days = Math.floor(ms / (1000 * 60 * 60 * 24));
    
      return `${days}d ${hours}h ${minutes}m ${seconds}s`;
    };
  
    const getCurrentGiveawayState = useCallback(() => {
      const now = new Date();
      for (const giveaway of upcomingGiveaways) {
        const startDate = new Date(giveaway.start);
        const endDate = new Date(giveaway.end);
  
        if (now >= startDate && now < endDate) {
          // Giveaway is active
          return {
            isActive: true,
            currentGiveaway: giveaway,
            timeRemaining: endDate - now
          };
        } else if (now < startDate) {
          // Next upcoming giveaway
          return {
            isActive: false,
            nextGiveaway: giveaway,
            timeUntilStart: startDate - now
          };
        }
      }
      return null; // No upcoming dates
    }, [upcomingGiveaways]);
  
    useEffect(() => {
      const updateState = () => {
        const giveawayState = getCurrentGiveawayState();
        if (giveawayState) {
          setCountdownTime(giveawayState.isActive ? giveawayState.timeRemaining : giveawayState.timeUntilStart);
          onGiveawayStateChange(giveawayState.isActive);
          setCurrentGiveawayIndex(upcomingGiveaways.indexOf(giveawayState.isActive ? giveawayState.currentGiveaway : giveawayState.nextGiveaway));
        } else {
          setCountdownTime(0);
          onGiveawayStateChange(false);
        }
      };
  
      updateState();
      const interval = setInterval(updateState, 1000);
      return () => clearInterval(interval);
    }, [getCurrentGiveawayState, onGiveawayStateChange, upcomingGiveaways]);
  
    return (
      <Typography
        variant="h6"
        className="monthlygiveaway"
        sx={{
          fontSize: { xs: "0.7rem", md: "1rem" },
          margin: {
            xs: "1%",
            md: "0.5%",
          },
        }}
      >
        <strong>
          {countdownTime > 0 ? (
            <>
              {new Date(upcomingGiveaways[currentGiveawayIndex].start) > new Date() 
                ? "Countdown to Next Giveaway:"
                : "Giveaway active for:"}
              <span
                style={{
                  color: "rgb(48, 166, 93)",
                }}
                className="giveaway"
              >
                {" "}
                {formatTime(countdownTime)}
              </span>
            </>
          ) : (
            "No upcoming giveaways"
          )}
        </strong>
      </Typography>
    );
  });
  
  export default Countdown;