import React, { useState, useCallback, useMemo } from 'react';
import { Wheel } from 'react-custom-roulette';
import { Box, Button, ThemeProvider, createTheme } from "@mui/material";

const getDynamicFontSize = (numPlayers, nameLength) => {
    // Larger font size for fewer players
  
    if (numPlayers <= 29) return "12";
    if (numPlayers <= 50) return "11";
    if (numPlayers <= 60) return "8";
    if (numPlayers <= 70) return "9";
    if (numPlayers <= 80) return "10";
  
    return "10"; // Smaller font size for many players
};

const theme = createTheme({
    palette: {
      customColor: {
        main: "#2a52be",
      },
    },
    typography: {
      button: {
        fontWeight: "bold",
        variant: "contained",
        marginBottom: "2%",
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          contained: {
            backgroundColor: "#2a52be",
            "&:hover": {
              backgroundColor: "#0000CD",
            },
          },
        },
      },
    },
  });

const SpinWheel = React.memo(({ players, giveawayActive, onSpinComplete, playerColors, fetchWinner, upcomingGiveaways }) => {
    const [mustSpin, setMustSpin] = useState(false);
    const [prizeNumber, setPrizeNumber] = useState(0);

    const getNextGiveawayStart = useCallback(() => {
      const now = new Date();
      const nextGiveaway = upcomingGiveaways.find(giveaway => new Date(giveaway.start) > now);
      return nextGiveaway ? new Date(nextGiveaway.start) : null;
    }, [upcomingGiveaways]);

    const formatStartTime = (startTime) => {
      if (!startTime) return 'No upcoming giveaways';
      const date = new Date(startTime);
      // Subtract 4 hours to convert UTC to EST
      date.setHours(date.getHours() - 4);
      return startTime.toLocaleString('en-US', { 
          month: 'numeric', 
          day: 'numeric', 
          year: 'numeric', 
          hour: '2-digit', 
          minute: '2-digit', 
          timeZone: 'America/New_York',
          timeZoneName: 'short'
      });
    };

    const nextGiveawayStart = getNextGiveawayStart();

    const handleSpinClick = useCallback(async () => {
        if (!mustSpin && giveawayActive) {
            try {
                const winnerName = await fetchWinner();
                const winnerIndex = players.findIndex(
                    player => player.option === winnerName
                );
                if (winnerIndex !== -1) {
                    setPrizeNumber(winnerIndex);
                    setMustSpin(true);
                } else {
                console.error("Winner not found in players list");
                }
            } catch (error) {
                console.error("Error fetching winner:", error);
            }
        }
    }, [mustSpin, giveawayActive, fetchWinner, players]);

    return (
        <>
            <Box
                className="wheel-container"
                sx={{
                width: {
                    xs: "87vw",
                    sm: "51vw",
                    md: "41vw",
                    lg: "31vw",
                    xl: "23vw",
                },
                height: {
                    xs: "87vw",
                    sm: "50vw",
                    md: "40vw",
                    lg: "30vw",
                    xl: "23vw",
                },
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative", // Required for absolute positioning of inner div
                }}
            >
                <Wheel
                    mustStartSpinning={mustSpin}
                    prizeNumber={prizeNumber}
                    data={players}
                    textColors={players.map(() => '#ffffff')}
                    backgroundColors={playerColors}
                    fontSize={getDynamicFontSize(players.length)}
                    outerBorderColor={"#e7eef8"}
                    outerBorderWidth={1}
                    radiusLineColor={"#e7eef8"}
                    radiusLineWidth={1}
                    textDistance={66}
                    radius={50} // Adjust to fit better within the container
                    onStopSpinning={() => {
                        setMustSpin(false);
                        onSpinComplete(prizeNumber);
                    }}
                    style={{
                        borderRadius: "50%",
                        width: "100%",
                        height: "100%",
                    }}
                />
            </Box>
            <ThemeProvider theme={theme} variant="contained" color="customRed">
            <Button
              id="lobby-spin"
              sx={{
                padding: "4px 5px",
                width: "auto",
                marginBottom: "10px",
                color: "white",
                background: "black",
                marginTop: "5px",
              }}
              variant="contained"
              disabled={!giveawayActive || mustSpin}
              onClick={handleSpinClick}
            >
              {mustSpin ? "Spinning..." : (giveawayActive ? "SPIN" : `Next giveaway: ${formatStartTime(nextGiveawayStart)}`)}
            </Button>
          </ThemeProvider>
        </>
    );
});

export default SpinWheel;