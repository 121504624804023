import React, { useState } from "react";
import "../styles/PlayerSignInModal.css";
import { useFormik } from "formik";
import * as yup from "yup";
import { useUI } from "../utils/UIContext";
import { useNavigate } from "react-router-dom";
import { useGame } from "../auth/gameProvider";

const PlayerSignInModal = ({ onClose }) => {
  const [email, setEmail] = useState("");
  const [joinNewsletter, setJoinNewsletter] = useState(false);
  const { handleNewAlert, handleAlertType } = useUI();
  const [isSignUp, setIsSignUp] = useState(true);
  const navigate = useNavigate();
  const game = useGame();

  const handleClose = () => {
    const newsModal = document.getElementsByClassName("news-modal-overlay");
    newsModal[0].style.display = "none";
  };

  const subscribeSchema = isSignUp
    ? yup.object().shape({
        name: yup.string().required("Please enter a valid username"),
        email: yup.string().email().required("Please enter a valid email"),
      })
    : yup.object().shape({
        email: yup.string().email().required("Please enter a valid email"),
      });

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
    },
    validationSchema: subscribeSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        if (isSignUp) {
          game.onPlay(values, true);
        } else {
          game.onPlay(values, false);
        }

        // API call to MailChimp
        if (joinNewsletter) {
          try {
            const mailChimpResponse = await fetch("/api/newsletter-signup", {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
              },
              body: JSON.stringify({ email: values.email }),
            });

            if (!mailChimpResponse.ok) {
              throw new Error("Failed to add subscriber to MailChimp");
            }
          } catch (error) {
            console.error("MailChimp error:", error);
            throw new Error(error.message);
          }
        }

        resetForm();
        onClose();
        if (!joinNewsletter) {
          handleNewAlert(
            "Joined Sweepstakes! Complete more tasks to increase your chances of winning!"
          );
          handleAlertType("success");
        } else {
          handleNewAlert(
            "Thanks for joining the newsletter! You earned 1 point!"
          );
          handleAlertType("success");
        }
      } catch (error) {
        throw new Error(error.message);
      }
    },
  });

  const handleJoinNewsletter = () => {
    setJoinNewsletter(!joinNewsletter);
  };

  const toggleSignUp = () => {
    setIsSignUp(!isSignUp);
    formik.resetForm();
  };

  return (
    <div className='news-modal-overlay'>
      <div className='news-modal-content'>
        <div className='news-modal-header'>
          <h1>{isSignUp ? 'Join Our Eco-Friendly Giveaways' : 'Sign In'}</h1>
        </div>
        <div className="news-modal-body">
          {isSignUp ? (
            <p>Join our giveaways with just one simple signup! Boost your chances to win by completing tasks that help the planet, like becoming a subscriber, referring friends, volunteering, and more.</p>
          ) : (
            <p>Sign in to access the sweepstakes and manage your tasks.</p>
          )}
          <form onSubmit={formik.handleSubmit}>
            {isSignUp && (
              <input
              type='name'
              placeholder='Create a username'
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name='name'
              required
            />
            )}
            {formik.errors.name && formik.touched.name ? (
              <div>{formik.errors.name}</div>
            ) : null}
            <input
              type="email"
              placeholder="Enter your email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="email"
              required
            />
            {formik.errors.email && formik.touched.email ? (
              <div>{formik.errors.email}</div>
            ) : null}
            {isSignUp && (
              <div className="newsletter-checkbox">
                <input
                  type="checkbox"
                  checked={joinNewsletter}
                  onChange={handleJoinNewsletter}
                  id="joinNewsletter"
                />
                <label htmlFor="joinNewsletter">Sign up for our Monthly Newsletter</label>
              </div>
            )}
            <button type="submit">
              {isSignUp ? "Register Game Account" : "Sign In"}
            </button>
          </form>
          <button id="toggle-signup" type="button" onClick={toggleSignUp}>
            {isSignUp
              ? "Already made a game account? Sign In Here"
              : "Don't have an account? Sign Up"}
          </button>
          <button
            id="toggle-signup"
            type="button"
            onClick={() => navigate("/home", { replace: true })}
          >
            Opt out of Giveaway
          </button>
        </div>
      </div>
    </div>
  );
};

export default PlayerSignInModal;
