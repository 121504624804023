import React, { useState, useEffect } from 'react';
import '../styles/Leaderboard.css';

function Leaderboard() {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  console.log(users)

  useEffect(() => {
    // Function to fetch leaderboard data
    const fetchLeaderboardData = async () => {
      setLoading(true);
      try {
        const response = await fetch('/users');
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();

        //sort users by number of events attended
        //!UI -- Eventually two separate columns, sort by events attended and sort by donations
        setUsers(data);
      } catch (err) {
        console.error('Failed to fetch leaderboard data:', err);
        setError('Failed to load leaderboard. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchLeaderboardData();

    // Set up polling to refresh data every X milliseconds
    const interval = setInterval(fetchLeaderboardData, 30000); // Adjust the 30000ms (30 seconds) interval as needed

    // Clear interval on component unmount to prevent memory leaks
    return () => clearInterval(interval);
  }, []); // Empty dependency array means this effect runs only once on mount

  if (loading) return <div className="loading-message">Loading leaderboard...</div>;
  if (error) return <div className="error-message">{error}</div>;

  return (
    <div className="leaderboard-container">
      <h2 className="leaderboard-title">Leaderboard</h2>
      <ol className="leaderboard-list">
        {users.map((user, index) => (
          <li key={index}>
            <span className="leaderboard-user">{user.username ? user.username : 'Anonymous Activist'}</span>
            <span className="leaderboard-events">{user.events_attended} events</span>
          </li>
        ))}
      </ol>
    </div>
  );
}

export default Leaderboard;
