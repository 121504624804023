import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import banner from '../images/ama-banner-full.png'; // Ensure the path is correct
import '../styles/Home.css';

function Home() {
  const navigate = useNavigate();
  const [showSubscribePopup, setShowSubscribePopup] = useState(false);

  useEffect(() => {
    function handleResize() {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    }

    // Set the height on mount
    handleResize();

    // Set the resize listener
    window.addEventListener('resize', handleResize);

    // Clean up the listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []); 

  const projects = [
    { name: 'Otamiri River, Nigeria 🇳🇬', slug: 'otamiri-river' },
    { name: 'Laguna del Condado, Puerto Rico 🇵🇷', slug: 'laguna-del-condado' },
    { name: 'Lagoa Rodrigo de Freitas, Brazil 🇧🇷', slug: 'lagoa-rodrigo-de-freitas' },
    { name: 'Leatherback Turtles, Puerto Rico 🐢', slug: 'leatherback-turtles' },
    { name: 'Passaic River, New Jersey 🇺🇸', slug: 'passaic-river' },
  ];

  const handleProjectSelect = (event) => {
    const selectedProjectIndex = projects.findIndex(p => p.slug === event.target.value);
    if (selectedProjectIndex >= 0) {
      navigate('/projects', { state: { selectedProjectIndex } });
    }
  };

  return (
    <div className="Home">
      <div className="banner-container">
        <img src={banner} alt="AMA Banner" className="home-banner" />
        <div className="banner-overlay">
          <p className="banner-text">Welcome to Ama Earth Group. Our mission is to regenerate the planet.</p>
          <div className="dropdown-container">
            <select id="project-dropdown" onChange={handleProjectSelect} defaultValue="">
              <option value="" disabled>Click here to see our projects</option>
              {projects.map((project, index) => (
                <option key={index} value={project.slug}>{project.name}</option>
              ))}
            </select>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
